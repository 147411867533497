import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, ViewChild } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { IrisMainMenuService } from '@iris/common/services/main-menu.service';
import { IrisHotkeysService } from '@iris/common/services/utils/iris-hotkeys.service';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IrisLogoService } from './common/services/logo.service';
import { GlobalNotificationI, IrisGlobalNotificationService } from '@iris/common/modules/ui/modules/global-announcement-banner/services/global-notification.service';
import { IrisLocalStorageService } from '@iris/common/services/utils/iris-local-storage.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit, OnDestroy {

  @ViewChild('sidenav') sidenav: MatSidenav;

  printMode = false;
  fullscreenMode = false;
  sidenavMode: MatDrawerMode;
  isPinned: boolean;
  notification$: Observable<GlobalNotificationI> = this.globalNotificationService.notification$;

  private isPageUnloading = false;
  private readonly _destroyed = new Subject<void>();

  constructor(
    private readonly hotkeysService: IrisHotkeysService, // just for service initialization
    private readonly logoService: IrisLogoService,
    private readonly storage: IrisLocalStorageService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly globalNotificationService: IrisGlobalNotificationService,
    private readonly mainMenuService: IrisMainMenuService,
    @Inject(DOCUMENT) private readonly document: Document,
    activatedRoute: ActivatedRoute,
  ) {
    this.sidenavMode = this.storage.getItem('sidebar_mode') || 'over';
    this.isPinned = this.sidenavMode === 'side';
    this.mainMenuService.updatePinnedState(this.isPinned);

    activatedRoute.queryParams.pipe(
      map(params => coerceBooleanProperty(params['print'])),
      tap(printMode => this.printMode = printMode),
      takeUntil(this._destroyed),
    ).subscribe();
    logoService.loadBrands().pipe(
      switchMap(() => logoService.activeBrand$),
      tap((activeBrand) => logoService.appendWebIcons(activeBrand?.brand)),
      takeUntil(this._destroyed),
    ).subscribe();
    globalNotificationService.getActive().pipe(
      takeUntil(this._destroyed),
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this.mainMenuService.sidenav = this.sidenav;
  }

  onSidenavModeChange(mode: MatDrawerMode): void {
    this.storage.setItem('sidebar_mode', mode);
    this.changeDetectorRef.markForCheck();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(): void {
    if (this.isPageUnloading) {
      return;
    }
    this.isPageUnloading = true;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreenModeHandler(): void {
    this.fullscreenMode = !(!this.document.fullscreenElement && !this.document['webkitIsFullScreen'] && !this.document['mozFullScreen'] && !this.document['msFullscreenElement']);
  }

  @HostListener('document:dragover', ['$event'])
  @HostListener('document:drop', ['$event'])
  disableDragAndDrop(event: DragEvent): void {
    const target = event.target as HTMLElement;
    if (target.classList?.contains('droppable')) {
      event.stopPropagation();
      return;
    }
    event.dataTransfer.effectAllowed = 'none';
    event.dataTransfer.dropEffect = 'none';
    event.preventDefault();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
